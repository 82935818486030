import { Route } from 'react-router';
import './App.css';
import { Layout } from './common/components/layout/Layout';
import { ApiKeyList } from './api-key/list/ApiKeyList';
import { Localisations } from './sdk-content/localisations/Localisations';
import { SupportFiles } from './support-files/SupportFiles';
import { Reports } from './reports/list/Reports';
import { ReportDetail } from './reports/detail/ReportDetail';
import { TermsAndConditions } from "./terms-conditions/TermsAndConditions";
import { Logs } from "./logs/Logs";
import routes from './routes';
import { AuthenticationContext } from "./hooks";
import { Redirect } from 'react-router-dom';

const App = () => {
  const authContext = AuthenticationContext.useAuthenticationContext();

  return authContext.isLoggedIn && (
    <Layout>
      <Route exact path={routes.home} component={ApiKeyList} />
      <Route exact path={routes.apiKeys} component={ApiKeyList} />
      <Route exact path={routes.sdkContent} component={Localisations} />
      <Route exact path={routes.supportFiles} component={SupportFiles} />
      <Route exact path={routes.reports} component={Reports} />
      <Route exact path={`${routes.reportDetail}/:feature`} component={ReportDetail} />
      <Route exact path={routes.terms} component={TermsAndConditions} />
      <Route exact path={routes.logs} component={Logs} />
      <Redirect to={routes.home} />
    </Layout>
  );
}

export default App;
