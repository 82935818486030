const routes = {
  home: '/',
  apiKeys: '/api-keys',
  sdkContent: '/sdk-content',
  supportFiles: '/support-files',
  reports: '/reports',
  reportDetail: '/feature',
  terms: '/terms-conditions',
  logs: '/logs',
  health: '/health',
};

export default routes;
