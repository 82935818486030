import React, { useState } from 'react';
import { Button, Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import './NavMenu.css';
import { hasPermission, Role } from '../../../user/roles';
import authenticator from '../../authentication/authenticator';
import endpoints from '../../../routes';

type NavMenuRoute = {
  route: string,
  caption: string,
  requiredRole: Role
}

interface NavMenuProps {
  roles: Role[]
}

export function NavMenu(props: NavMenuProps) {
  const routes: NavMenuRoute[] = [
      { route: endpoints.apiKeys, caption: "Api Keys", requiredRole: "operations"},
      { route: endpoints.sdkContent, caption: "SDK Content", requiredRole: "content"},
      { route: endpoints.supportFiles, caption: "SDK Support Files", requiredRole: "operations"},
      { route: endpoints.reports, caption: "Reports", requiredRole: "operations"},
      { route: endpoints.terms, caption: "Terms And Conditions", requiredRole: "content"},
      { route: endpoints.logs, caption: "Logs", requiredRole: "operations"}
  ]

  const [collapsed, setCollapsed] = useState(true)

  function toggleNavbar(): void {
    setCollapsed(!collapsed)
  }

  return (
    <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow" sticky="top">
      <Container>
        <NavbarBrand tag={Link} to={routes[0].route}>Mobile ID API Backoffice</NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
          <ul className="navbar-nav flex-grow">
            {routes.map((routeItem) => (
            <React.Fragment key={routeItem.caption}>
              {hasPermission(props.roles, routeItem.requiredRole) &&
              <NavMenuItem route={routeItem.route} caption={routeItem.caption} />
              }
            </React.Fragment>)
            )}
            <Button className="logout-btn" color="outline-secondary" onClick={_ => authenticator.logout()}>Logout</Button>
          </ul>
        </Collapse>
      </Container>
    </Navbar>
  )
}

type NavMenuItemProps = {
  route: string,
  caption: string
}

function NavMenuItem(props: NavMenuItemProps) {
    const location = useLocation();
    let match = useRouteMatch(props.route) || (location.pathname === "/" && props.route === endpoints.apiKeys)

  return (
    <NavItem>
      <NavLink tag={Link} className={"navlink-content"+(match ? " selected" : "")} to={props.route}>{props.caption}</NavLink>
    </NavItem>
  )
}