import { Component } from "react";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import ReportsService from "../common/services/reports-service";
import { Paginator } from "../../common/components/paginator/Paginator";
import { ReportDetailFiltersData } from "../common/models/detail-filters-data";
import { PagedResult } from "../../common/models/paged-result";
import { TransactionDetailSummary } from "../common/models/transaction-detail-summary-dto";
import { RouteComponentProps } from "react-router-dom";
import { ReportsDetailSearchRequest } from "../common/models/reports-detail-search-request";
import { ReportsSearchRequest } from "../common/models/reports-search-request";
import TransactionDetailList from "./components/TransactionDetailList";
import ToastService from "../../common/services/toast-service";
import { DeviceData } from '../common/models/device-data';
import "../common/Reports.css";

var androidDevices = require('android-device-list');

export interface ColumnDef {
    header: string,
    accessor: string,
}

export interface FilterDef {
    key: string,
    value: string,
    label: string
}

const columns: ColumnDef[] = [
    {
        header: 'Type',
        accessor: 'featureName'
    }, {
        header: 'Timestamp',
        accessor: 'createdAt'
    },
    {
        header: 'Device',
        accessor: 'deviceId'
    },
    {
        header: 'Model',
        accessor: 'deviceModel'
    },
    {
        header: 'DeviceOS',
        accessor: 'deviceOS'
    },
    {
        header: 'Status',
        accessor: 'status'
    },
    {
        header: 'ErrorMessage',
        accessor: 'errorMessage'
    }
];

const deviceOsList: FilterDef[] = [
    {
        key: "android",
        value: "Android",
        label: "Android"
    },
    {
        key: "ios",
        value: "Ios",
        label: "iOS"
    }
]

const statusList: FilterDef[] = [
    {
        key: "failure",
        value: "false",
        label: "Failure"
    },
    {
        key: "success",
        value: "true",
        label: "Success"
    }
]

interface ReportDetailState {
    data?: PagedResult<TransactionDetailSummary>;
    filtersData?: ReportDetailFiltersData;
    isSearching: boolean;
    status?: string;
    deviceModel?: string;
    deviceOS?: string
}

export class ReportDetail extends Component<RouteComponentProps, ReportDetailState> {

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            isSearching: false
        }
    }

    componentDidMount() {
        this.setupFilters();
        this.submitSearch();
    }

    componentDidUpdate(prevProps: RouteComponentProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.submitSearch();
        }
    }

    render() {
        return (
            <Container>
                <Container>
                    <Spinner color="primary" className={this.state.filtersData ? "invisible" : "visible"}></Spinner>

                    {this.state?.filtersData &&
                        <Form>
                            <Row form>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label for="status">Status</Label>
                                        <Input type="select" name="select" id="statusSelect"
                                            onChange={e => this.statusChanged(e.target.value)}
                                            value={this.state.status || ''}>
                                            <option key="all" value="">All</option>
                                            {statusList.map(status => (
                                            <option key={status.key} value={status.value}>{status.label}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label for="device">Device Model</Label>
                                        <Input type="select" name="select" id="deviceSelect"
                                            onChange={e => this.deviceModelChanged(e.target.value)}
                                            value={this.state.deviceModel || ''}>  
                                            <option key="all" value="">All</option>
                                            {this.state.filtersData?.deviceModels.map(model => (
                                                <option key={model} value={model}>{this.deviceLabelForModel(model)}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label for="deviceOs">Device OS</Label>
                                        <Input type="select" name="select" id="deviceOSSelect"
                                            onChange={e => this.deviceOSChanged(e.target.value)}
                                            value={this.state.deviceOS || ''}>  
                                            <option key="all" value="">All</option>
                                            {deviceOsList.map(os => (
                                                <option key={os.key} value={os.value}>{os.label}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col className="align-self-end col-md-auto">
                                    <Button className="search-button" color="primary"
                                        onClick={() => this.submitSearch()}>Search</Button>
                                </Col>
                                <Col className="align-self-end col-md-auto">
                                    <Button className="search-button" color="secondary"
                                        onClick={() => this.resetFilters() }>Reset</Button>
                                </Col>
                            </Row>
                            <Row className="no-gutters">
                                <Col sm={2}>
                                    <Label for="totalDevices">Total Devices: </Label>
                                    <Label for="totalDevices" className="total-value">{this.state.filtersData.totalDevices}</Label>
                                </Col>
                                <Col sm={2}>
                                    <Label for="totalTransactions">Total Transactions: </Label>
                                    <Label for="totalTransactions" className="total-value">{this.state.filtersData.totalTransactions}</Label>
                                 </Col>
                            </Row>
                        </Form>
                    }
                </Container>

                <Spinner color="primary" className={this.state.isSearching ? "visible" : "invisible"}></Spinner>

                {this.state.data &&
                <Container>
                    <TransactionDetailList columns={columns} result={this.state.data} />
                    <Paginator
                        pageCount={this.state.data.pageCount}
                        changePage={(page: number) => this.handlePageChange(page)}
                        currentPage={this.state.data.currentPage} />
                </Container>
                }
            </Container>
        );
    }

    private handlePageChange(page: number) {
        const params = this.getSearchParams()

        if (params.apiKey === null || params.apiKey === undefined) {
            return
        }

        var url = this.setupUrlFor(params);

        this.props.history.push(url + "&page=" + page);
    }

    private setupUrlFor = (params: ReportsSearchRequest) => {
        var queryParams = Object.keys(params)
            .filter(key => params[key] != null)
            .map(key => key + '=' + params[key])
            .join('&');
    
        return this.props.location.pathname + '?' + queryParams;
    }

    private statusChanged(status: string) {
        this.setState({
            status: status
        });
    }

    private deviceModelChanged(model: string) {
        this.setState({
            deviceModel: model
        });
    }

    private deviceOSChanged(os: string) {
        this.setState({
            deviceOS: os
        });
    }

    private deviceLabelForModel(model: string) {
        var dataForModel: [DeviceData] = androidDevices.getDevicesByModel(model)
        if(dataForModel.length > 0) {
            return String(dataForModel[0].name);
        }

        return String(model)
    }

    private setupFilters() {

        this.setState({
            isSearching: true
        });

        const params = this.getSearchParams()

        if (params.apiKey === null || params.apiKey === undefined) {
            return
        }

        var featureId = getLastItem(this.props.location.pathname)

        ReportsService.getDetailFiltersData(featureId, params)
            .then((data) => {
                this.setState({ filtersData: data })
            }).catch((error) => {
                ToastService.showToast("Error fetching detail filters", error.response.data.detail);
            });;
    }

    private getSearchParams = () => {
        const query = new URLSearchParams(this.props.location.search);
        var apiKey = query.get('apiKey')
        var fromDate = query.get('fromDate');
        var toDate = query.get('toDate');
        var sdkVersion = query.get('sdkVersion');

        var params: ReportsSearchRequest = {
            apiKey: apiKey,
            sdkVersion: sdkVersion,
            fromDate: fromDate,
            toDate: toDate
        }

        return params;
    }

    private resetFilters() {
        this.setState({
            status: "",
            deviceModel: "",
            deviceOS: "",
            data: undefined
        }, () => {
            this.submitSearch()
        });
    }

    private submitSearch() {

        this.setState({
            isSearching: true
        });

        const params = this.getSearchParams()

        if (params.apiKey === null || params.apiKey === undefined) {
            return
        }

        var request: ReportsDetailSearchRequest = {
            apiKey: params.apiKey,
            sdkVersion: params.sdkVersion,
            fromDate: params.fromDate,
            toDate: params.toDate,
            deviceModel: this.state.deviceModel,
            deviceOS: this.state.deviceOS,
            status: this.state.status
        }

        var featureId = getLastItem(this.props.location.pathname)

        ReportsService.detail(featureId, this.props.location.search, request)
            .then((page) => {
                this.setState({
                    data: page,
                    isSearching: false
                });
            }).catch((error) => {
                ToastService.showToast("Error applying detail filters", error.response.data.detail);
                this.setState({
                    isSearching: false
                });
            });;
    }

}

const getLastItem = (thePath: string) => thePath.substring(thePath.lastIndexOf('/') + 1)
