import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ReportsSearchRequest } from "../../common/models/reports-search-request";

const TransactionCell = (props: { searchData: ReportsSearchRequest, featureType: string, data: { [key: string]: any } }) => {
    let { searchData, featureType, data } = props;

    switch (featureType) {
        case 'featureName':
            return <NavLink tag={Link} to={setupUrlForFeatureType(data[featureType], searchData)}> {data[featureType]}</NavLink >;
        case 'successRate':
            return <>{formatAsPercentage(data[featureType])}</>
        default:
            return <>{data[featureType]}</>
    }
}

const formatAsPercentage = (value: any) => {
    return value + "%";
}

const setupUrlForFeatureType = (featureType: string, searchData: ReportsSearchRequest) => {
    var queryParams = Object.keys(searchData)
        .filter(key => searchData[key] != null)
        .map(key => key + '=' + searchData[key])
        .join('&');

    return 'feature/' + featureType.toLowerCase() + '?' + queryParams;
}

export default TransactionCell;
