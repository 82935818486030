import axios from "../../../common/api/custom-axios";
import configs from "../../../configs";
import { FiltersData } from "../models/filters-data";
import { LocalisationsMap } from "../models/localisations-map";
import { AddLocalisationsRequest } from "../models/add-localisations-request";
import { UpdateLocalisationsRequest } from "../models/update-localisations-request";

export class SDKContentService {
    
    private sdkContentRelUri = "sdk-content";
    private localisationsRelUri = "localisations";
    private addRelUri = "add";

    getFiltersData(): Promise<FiltersData> {
        return axios.get([configs.endpoint, this.sdkContentRelUri].join("/")).then(rsp => rsp.data);
    }

    addLanguage(apiKey: string, language: string, request: AddLocalisationsRequest) {
        return axios
            .post([configs.endpoint, this.sdkContentRelUri, this.localisationsRelUri, this.addRelUri, apiKey, language].join("/"), request)
            .then(rsp => rsp.data);
    }

    getLocalisations(apiKey: string, language: string, deviceOS: string): Promise<LocalisationsMap> {
        return axios.get([configs.endpoint, this.sdkContentRelUri, this.localisationsRelUri, apiKey, language, deviceOS].join("/")).then(rsp => rsp.data);
    }

    updateLocalisations(apiKey: string, language: string, request: UpdateLocalisationsRequest) {
        return axios.put([configs.endpoint, this.sdkContentRelUri, this.localisationsRelUri, apiKey, language].join("/"), request).then(rsp => rsp.data);
    }
}

const sdkContentService = new SDKContentService();

export default sdkContentService;