import { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Container, Form, Row, FormGroup, Col, Label, Input, Spinner } from "reactstrap";
import { FilesData, RegulaLicense, CSCACertificate } from "./common/models/files-data";
import { UploadLicenseRequest } from "./common/models/upload-license-request";
import SupportFilesService from "./common/services/support-files.service";
import ToastService from "../common/services/toast-service";
import { Licenses } from "./licenses/Licenses";
import { CscaCertificates } from "./csca-certificates/CscaCertificates";
import { UploadCertificatesRequest } from "./common/models/upload-certificates-request";

interface SupportFilesState {
    filesData?: FilesData
    selectedApiKey?: string
    isLoading: boolean
}

export class SupportFiles extends Component<RouteComponentProps, SupportFilesState> {

    componentDidMount() {
        this.getFilesData()
    }

    render() {
        return (
            <Container>
                <Spinner color="primary" className={this.state?.filesData ? "invisible" : "visible"}></Spinner>
                {this.state?.filesData &&
                    <Form>
                        <h5>Upload support files</h5>
                        <Row form>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="apiKeySelect">Select client:</Label>
                                    <Input type="select" disabled={this.state.isLoading} name="select" id="apiKeySelect"
                                        defaultValue={''}
                                        onChange={e => this.onApiKeyChanged(e.target.value)}>
                                        <option value="" hidden></option>
                                        {this.state.filesData?.clientData.map(clientData => (
                                            <option key={clientData.apiKeyToken} value={clientData.apiKeyToken}>{clientData.apiKeyName}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                }
                {this.state?.selectedApiKey && !this.state?.isLoading &&
                    <Container>
                    <Licenses apiKey={this.state.selectedApiKey} currentLicense={this.getCurrentLicense()} onDelete={(license) => this.onDeleteLicense(license)} onSubmit={(lic, expiryDate)=> this.onLicenseSave(lic, expiryDate)}/>
                    <CscaCertificates apiKey={this.state.selectedApiKey} currentCertificates={this.getCurrentCertificates()} onDelete={(certificate) => this.onDeleteCertificate(certificate)} onSubmit={(certs) => this.onCertificatesSave(certs)}/>
                    </Container>
                }
                {this.state?.isLoading &&
                    <Container>
                        <Spinner color="primary" className={this.state?.isLoading ? "visible" : "invisible"}></Spinner>
                    </Container>
                }
            </Container>
        );
    }

    private getFilesData() {
        SupportFilesService.getClientsData()
            .then((data) => {
                this.setState({ filesData: data })
            })
    }

    private onApiKeyChanged(apiKeyToken: string) {
        this.setState({
            selectedApiKey: apiKeyToken,
        })
    }

    private onLicenseSave(license: File, expiryDate: string) {
        if (this.state.selectedApiKey) {
            let request: UploadLicenseRequest = {
                file: license,
                expiryDate: expiryDate
            }

            this.setState({ isLoading: true })
    
            SupportFilesService.uploadLicense(this.state.selectedApiKey, request)
                .then(() => {
                    this.setState({ isLoading: false })
                    ToastService.showToast("Success", "Uploaded license file", true)
                })
                .catch((error) => {
                    this.setState({ isLoading: false })
                    ToastService.showToast("Error uploading license file", error)
                })
                .then(() => { this.getFilesData() })
        }
    }

    private onCertificatesSave(certificates: File) {
        if (this.state.selectedApiKey) {
            let request: UploadCertificatesRequest = {
                file: certificates,
            }

            this.setState({ isLoading: true })
    
            SupportFilesService.uploadCertificates(this.state.selectedApiKey, request)
                .then(() => {
                    this.setState({ isLoading: false })
                    ToastService.showToast("Success", "Uploaded certificates file", true)
                })
                .catch((error) => {
                    this.setState({ isLoading: false })
                    ToastService.showToast("Error uploading certificates file", error)
                })
                .then(() => { this.getFilesData() })
        }
    }

    private onDeleteCertificate(certificate: CSCACertificate) {
        this.setState({ isLoading: true })

        SupportFilesService.deleteCertificate(certificate.id)
            .then(() => {
                this.setState({ isLoading: false })
                ToastService.showToast("Success", "Deleted certificate file", true)
            })
            .catch((error) => {
                this.setState({ isLoading: false })
                ToastService.showToast("Error deleting certificate file", error)
            })
            .then(() => { this.getFilesData() })
    }

    private onDeleteLicense(license: RegulaLicense) {
        this.setState({ isLoading: true })

        if (this.state.selectedApiKey) {
            SupportFilesService.deleteLicense(this.state.selectedApiKey)
                .then(() => {
                    this.setState({ isLoading: false })
                    ToastService.showToast("Success", "Deleted license file", true)
                })
                .catch((error) => {
                    this.setState({ isLoading: false })
                    ToastService.showToast("Error deleting license file", error)
                })
                .then(() => { this.getFilesData() })
        }
    }

    private getCurrentLicense(): RegulaLicense|undefined {
        if (this.state.selectedApiKey) {
            return this.state.filesData?.clientData?.filter(data => data.apiKeyToken === this.state.selectedApiKey)?.[0].regulaLicense
        } else {
            return undefined
        }
    }

    private getCurrentCertificates(): CSCACertificate[]|undefined {
        if (this.state.selectedApiKey) {
            return this.state.filesData?.clientData?.filter(data => data.apiKeyToken === this.state.selectedApiKey)?.map(clientData => clientData.cscaCertificates).flat()
        } else {
            return undefined
        } 
    }
}