import { PagedResult } from '../../../common/models/paged-result';
import config from '../../../configs';
import { TransactionSummary } from '../models/transaction-summary-dto';
import { TransactionDetailSummary } from '../models/transaction-detail-summary-dto';
import { ReportFiltersData } from "../models/filters-data";
import { ReportDetailFiltersData } from "../models/detail-filters-data";
import { ReportsSearchRequest } from "../models/reports-search-request";
import { ReportsDetailSearchRequest } from "../models/reports-detail-search-request";
import axios from '../../../common/api/custom-axios';

export class ReportsService {

    private reportsRelUri = "reports";
    private reportFiltersRelUri = "filters";

    getFiltersData(): Promise<ReportFiltersData> {
        return axios.get([config.endpoint, this.reportsRelUri, this.reportFiltersRelUri].join("/")).then(rsp => rsp.data);
    }

    search(searchString: string, request: ReportsSearchRequest): Promise<PagedResult<TransactionSummary>> {
        return axios.post([config.endpoint, this.reportsRelUri].join("/") + searchString, request).then(rsp => rsp.data);
    }

    detail(featureId: string, searchString: string, request: ReportsDetailSearchRequest): Promise<PagedResult<TransactionDetailSummary>> {
        return axios.post([config.endpoint, this.reportsRelUri, featureId].join("/") + searchString, request).then(rsp => rsp.data);
    }

    getDetailFiltersData(featureId: string, request: ReportsSearchRequest): Promise<ReportDetailFiltersData> {
        return axios.post([config.endpoint, this.reportsRelUri, this.reportFiltersRelUri, featureId].join("/"), request).then(rsp => rsp.data);
    }
};

const reportsService = new ReportsService();

export default reportsService;
