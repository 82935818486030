import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import ToastService from "../../common/services/toast-service";
import { FiltersData } from "../common/models/filters-data";
import { UpdateLocalisationsRequest } from "../common/models/update-localisations-request";
import SDKContentService from "../common/services/sdk-content.service";
import config from '../../configs';
// Localisation Add
import { LocalisationAddModal } from "./new/LocalisationAddModal";
import { AddLocalisationsRequest } from "../common/models/add-localisations-request";

interface LanguageStrings {
    hasChanged: boolean,
    strings: { [key: string]: string }
}

interface LocalisationsState {
    filtersData?: FiltersData
    selectedApiKey?: string
    selectedOS?: string
    selectedLanguage?: string
    placeholders?: {[language: string] : {[key: string]: string} }
    localisations?: {[language: string] : LanguageStrings }
    isLoading: boolean
    localisationAddModalOpen: boolean
    defaultPlaceholderLanguage: string
}

export class Localisations extends Component<RouteComponentProps, LocalisationsState> {

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            isLoading: false,
            localisationAddModalOpen: false,
            defaultPlaceholderLanguage: config.defaultLanguage,
        }
    }

    componentDidMount() {
        this.getFiltersData();
    }

    render() {
        return (
            <Container>
                {this.state?.filtersData && this.state.filtersData?.clientsData.length > 0 &&
                    <Form>
                        <Row form>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="apiKey">Client</Label>
                                    <Input type="select" name="select" id="apiKeySelect"
                                        value={this.state.selectedApiKey ?? ''}
                                        onChange={e => this.apiKeyChanged(e.target.value)}>
                                            {this.state.filtersData?.clientsData.map(clientData => (
                                                <option key={clientData.apiKeyToken} value={clientData.apiKeyToken}>{clientData.apiKeyName}</option>
                                            ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col sm={1}>
                                <FormGroup>
                                    <Label for="apiKey">OS</Label>
                                    <Input type="select" name="select" id="osSelect"
                                        value={this.state.selectedOS ?? ''}
                                        onChange={e => this.operatingSystemChanged(e.target.value)}>
                                        {this.state.filtersData?.devicesOS.map(os => (
                                            <option key={os} value={os}>{os}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            {this.state.selectedApiKey && this.state.selectedApiKey !== '' && this.getAvailableLanguages(this.state.selectedApiKey).length > 0 && this.state.selectedLanguage &&
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label for="language">Language</Label>
                                        <Input type="select" name="select" id="languageSelect"
                                            value={this.state.selectedLanguage}
                                            onChange={e => {
                                                if (this.state.selectedApiKey && this.state.selectedOS) {
                                                    this.languageChanged(this.state.selectedApiKey, e.target.value, this.state.selectedOS)
                                                }
                                            }}>
                                                {this.state.selectedApiKey && this.getAvailableLanguages(this.state.selectedApiKey).map(language => (
                                                    <option selected={this.state.selectedLanguage === language} key={language}>{language}</option>
                                                ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            }
                            <Col xs="auto" className="align-self-end">
                                <Button disabled={this.state.selectedApiKey === undefined || this.state.selectedApiKey === ''} className="generate-button" color="primary"
                                    onClick={() => this.toggleLocalisationAddModal()}>Add New Language</Button>
                            </Col>
                            <Col></Col>
                            <Col xs="auto" className="align-self-end">
                                <Button className="generate-button" color="primary"
                                    disabled={!this.state.selectedLanguage || !this.isFormValid() ||
                                            (this.state.selectedLanguage !== undefined &&
                                            this.state.localisations !== undefined &&
                                            this.state.localisations[this.state.selectedLanguage] !== undefined &&
                                            !this.state.localisations[this.state.selectedLanguage].hasChanged)}
                                    onClick={() => this.updateSelectedLocalisations()}>Update</Button>
                            </Col>
                        </Row>
                    </Form>
                }
                {(this.state?.filtersData === undefined || this.state.filtersData?.clientsData.length === 0) &&
                    <h4>No clients configured</h4>
                }
                {this.state?.selectedApiKey && this.getAvailableLanguages(this.state.selectedApiKey)?.length === 0 &&
                    <h4>No localisations file configured</h4>
                }
                {this.state?.localisations && this.state?.selectedLanguage && this.state?.localisations[this.state.selectedLanguage] && this.state?.localisations[this.state.selectedLanguage].strings &&
                    <Container>
                        <Form>
                            {Object.keys(this.state.localisations[this.state.selectedLanguage].strings).map(key => (
                                <FormGroup row key={key}>
                                    <Label sm={6}>{key}</Label>
                                    <Col sm={6}>
                                        <Input
                                            type="text"
                                            placeholder={this.getPlaceholderForLanguageAndKey(key)}
                                            onChange={e => {
                                                if (this.state.selectedLanguage) {
                                                    this.updateLocalisations(this.state.selectedLanguage, key, e.target.value)
                                                }
                                            }}
                                            value={this.state.selectedLanguage ? this.state.localisations?.[this.state.selectedLanguage].strings[key] ?? "" : ""}>
                                        </Input>
                                    </Col>
                                </FormGroup>
                            ))}
                        </Form>
                    </Container>
                }
                <Spinner color="primary" className={this.state.isLoading ? "visible" : "invisible"}></Spinner>
                
                {this.state?.selectedApiKey &&
                    <LocalisationAddModal 
                        isOpen={this.state.localisationAddModalOpen} 
                        apiKey={this.state.selectedApiKey}
                        toggle={() => this.toggleLocalisationAddModal()} 
                        submit={(language: string, request: AddLocalisationsRequest) => this.addLocalisations(language, request)}/>
                }
            </Container>
        );
    }

    // MODALS

    private toggleLocalisationAddModal() {
        this.setState({localisationAddModalOpen: !this.state.localisationAddModalOpen});
    }

    // CONTROLS

    private localisationAdded(language: string) {
        var filtersData = this.state.filtersData

        if (this.state.selectedApiKey && filtersData?.clientsData.find(c => c.apiKeyToken === this.state.selectedApiKey)?.availableLanguages.indexOf(language) === -1) {
            filtersData?.clientsData.find(c => c.apiKeyToken === this.state.selectedApiKey)?.availableLanguages.push(language)
        }
        
        this.setState({
            selectedLanguage: language,
            filtersData: filtersData
        })

        if (this.state.selectedApiKey !== undefined && this.state.selectedOS !== undefined) {
            this.languageChanged(this.state.selectedApiKey, language, this.state.selectedOS)
        }
    }

    private getFiltersData() {
        this.setState({isLoading: true})

        SDKContentService.getFiltersData()
            .then((data) => {
                this.setState({
                    filtersData: data
                })
            }).then(() => {
                if (this.state.filtersData && this.state.filtersData.clientsData) {
                    this.setState({
                        selectedApiKey: this.state.filtersData?.clientsData?.[0]?.apiKeyToken,
                        selectedOS: this.state.filtersData.devicesOS?.[0],
                        selectedLanguage: this.state.defaultPlaceholderLanguage
                    })
                }
            }).then(() => {
                if (this.state.filtersData && this.state.filtersData.clientsData &&
                    this.state.selectedApiKey && this.state.selectedOS && this.state.selectedLanguage) {
                    this.languageChanged(this.state.selectedApiKey, this.state.selectedLanguage, this.state.selectedOS)
                }
            }).finally(() => this.setState({isLoading: false}));
    }

    private getPlaceholderForLanguageAndKey(key: string): string {
        if (this.state.selectedLanguage === undefined) { return "" }

        var defaultPlaceholder = this.state.placeholders?.[this.state.defaultPlaceholderLanguage]?.[key] ?? ""
        var languagePlaceholder = this.state.placeholders?.[this.state.selectedLanguage]?.[key]

        return languagePlaceholder !== undefined && languagePlaceholder !== "" ? languagePlaceholder : defaultPlaceholder
    }

    private getAvailableLanguages(apiKeyToken: string): string[] {
        return this.state?.filtersData?.clientsData.find(c => c.apiKeyToken === apiKeyToken)?.availableLanguages ?? [];
    }

    private apiKeyChanged(apiKeyToken: string) {
        this.setState({
            selectedApiKey: apiKeyToken,
            selectedOS: this.state.selectedOS,
            selectedLanguage: this.state.defaultPlaceholderLanguage,
            localisations: undefined
        }, () => {
            if (this.state.selectedApiKey && this.state.selectedOS && this.state.selectedLanguage) {
                this.languageChanged(this.state.selectedApiKey, this.state.selectedLanguage, this.state.selectedOS);
            }
        })
    }

    private operatingSystemChanged(os: string) {
        this.setState({
            selectedApiKey: this.state.selectedApiKey,
            selectedOS: os,
            selectedLanguage: this.state.selectedLanguage,
            localisations: undefined
        }, () => {
            if (this.state.selectedApiKey && this.state.selectedOS && this.state.selectedLanguage) {
                this.languageChanged(this.state.selectedApiKey, this.state.selectedLanguage, this.state.selectedOS);
            }
        })
    }

    private languageChanged(apiKey: string, language: string, deviceOS: string) {
        if (language === "") {
            this.setState({
                selectedLanguage: undefined
            })

            return
        }

        if (!(this.state?.localisations && this.state.selectedOS && this.state?.localisations[language])) {
            this.setState({isLoading: true})
            SDKContentService.getLocalisations(apiKey, language, deviceOS)
                .then((data) => {
                    this.setState({
                        selectedLanguage: language,
                        localisations: {
                            ...this.state.localisations, 
                            [language]: {
                                hasChanged: false,
                                strings: data.localisations
                            }
                        },
                        placeholders: {
                            ...this.state.placeholders,
                            [language]: data.localisations
                        }
                    })
                })
                .finally(() => this.setState({isLoading: false}))
        } else {
            this.setState({
                selectedLanguage: language
            });
        }
    }

    private updateLocalisations(language: string, key: string, value: string) {
        if (this.state?.localisations === undefined) { return }

        this.setState({
            localisations: {
                ...this.state.localisations,
                [language]: {
                    strings: {...this.state.localisations?.[language].strings, [key]: value},
                    hasChanged: true
                }
            },
        });
    }

    private updateSelectedLocalisations() {
        if (!this.state.selectedApiKey || !this.state.selectedLanguage || !this.state.localisations || !this.state.localisations[this.state.selectedLanguage]) {
            console.error("Invalid state");
            return;
        }

        if (this.state.selectedOS !== undefined) {
            var request: UpdateLocalisationsRequest = {
                localisations: this.state.localisations[this.state.selectedLanguage].strings,
                deviceOS: this.state.selectedOS
            }

            SDKContentService.updateLocalisations(this.state.selectedApiKey, this.state.selectedLanguage, request)
                .then(() => {
                    var localisations = { ...this.state.localisations }
                    if (this.state.selectedLanguage && this.state.selectedOS && this.state.selectedApiKey) {
                        delete localisations[this.state.selectedLanguage];
                        this.setState({ localisations: localisations });
                        this.languageChanged(this.state.selectedApiKey, this.state.selectedLanguage, this.state.selectedOS);
                    }

                    ToastService.showToast("Success", "Localisation file updated", true);
                })
                .catch((error) => {
                    ToastService.showToast("Error updating localisation file", error);
                });
        }
    }

    private addLocalisations(language: string, request: AddLocalisationsRequest) {
        if (this.state.selectedApiKey !== undefined) {

            this.setState({ isLoading: true })

            SDKContentService.addLanguage(this.state.selectedApiKey, language, request)
                .then((data) => {
                    this.localisationAdded(language)
                })
                .finally(() => this.setState({ isLoading: false }))
                .catch((error) => {
                    ToastService.showToast("Error validating language", "")
                })
        }
    }

    private isFormValid(): boolean {
        if (this.state.selectedLanguage && this.state.selectedOS && this.state.localisations && this.state.localisations[this.state.selectedLanguage]) {
            for(var localisation in this.state.localisations[this.state.selectedLanguage].strings) {
                if (this.state.localisations[this.state.selectedLanguage].strings[localisation] === undefined || this.state.localisations[this.state.selectedLanguage].strings[localisation] === "") {
                    return false;
                }
            }
            return true;
        }
        return false;
    }
}