import { AxiosInstance } from 'axios';
import authenticator from '../authentication/authenticator';
import ToastService from '../services/toast-service';

export function setupAxiosAuthInterceptor(axios: AxiosInstance): AxiosInstance {
    axios.interceptors.response.use(undefined, async error => {
        if (error.response && error.response.status === 401) {
            await authenticator.clearAccessToken();
            await authenticator.login();
        }

        if (error.response && error.response.status === 403) {
            ToastService.showToast("Unauthorized", "You have no permissions to use this feature")
        }

        return Promise.reject(error);
    });

    axios.interceptors.request.use(async config => {
        const token = await authenticator.getAccessToken()
            .catch(async _ => await authenticator.login())
        config.headers['Authorization'] = `Bearer ${token}`
        return config
    })

    return axios
}

