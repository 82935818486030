import { Component } from "react";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner, FormFeedback } from "reactstrap";
import ReportsService from "../common/services/reports-service";
import { Paginator } from "../../common/components/paginator/Paginator";
import "../common/Reports.css";
import { ReportFiltersData } from "../common/models/filters-data";
import { PagedResult } from "../../common/models/paged-result";
import { TransactionSummary } from "../common/models/transaction-summary-dto";
import { RouteComponentProps } from "react-router-dom";
import ToastService from "../../common/services/toast-service";
import TransactionList from "./components/TransactionList";
import { ReportsSearchRequest } from "../common/models/reports-search-request";

export interface ColumnDef {
    header: string,
    accessor: string,
}

const columns: ColumnDef[] = [
    {
        header: 'Type',
        accessor: 'featureName'
    },
    {
        header: 'Total',
        accessor: 'featureCount'
    },
    {
        header: 'SDK Version',
        accessor: 'enrolmentSdkVersion'
    },
    {
        header: 'Success Rate',
        accessor: 'successRate'
    }
];

interface ReportsState {
    data?: PagedResult<TransactionSummary>;
    filtersData?: ReportFiltersData;
    fromDate?: string | undefined;
    toDate?: string | undefined;
    apiKey?: string | undefined;
    sdkVersion?: string | undefined;
    isSearching: boolean
}

export class Reports extends Component<RouteComponentProps, ReportsState> {

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            isSearching: false
        }
    }

    componentDidMount() {
        this.getFiltersData();
    }

    componentDidUpdate(prevProps: RouteComponentProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.submitSearch();
        }
    }

    render() {
        return (
            <Container>
                <Container>
                    <Spinner color="primary" className={this.state.filtersData ? "invisible" : "visible"}></Spinner>

                    {this.state?.filtersData &&
                    <Form>
                        <Row form>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="apiKey">Client</Label>
                                    <Input type="select" name="select" id="apiKeySelect"
                                        onChange={e => this.apiKeyChanged(e.target.value)}
                                        value={this.state.apiKey || ''}>
                                            <option value="" hidden></option>
                                            {this.state.filtersData?.clients.map(client => (
                                                <option key={client.apiKeyToken} value={client.apiKeyToken}>{client.apiKeyName}</option>
                                            ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col sm={2}>
                                <FormGroup>
                                    <Label for="fromDate">From</Label>
                                    <Input
                                        type="date"
                                        name="fromDate"
                                        onChange={e => this.fromDateChanged(e.target.value)}
                                        value={this.state.fromDate || ''}>
                                    </Input>
                                    <FormFeedback>This field is required</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col sm={2}>
                                <FormGroup>
                                    <Label for="toDate">To</Label>
                                    <Input
                                        type="date"
                                        name="toDate"
                                        onChange={e => this.toDateChanged(e.target.value)}
                                        value={this.state.toDate || ''}>
                                    </Input>
                                    <FormFeedback>This field is required</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col className="align-self-end col-md-auto">
                                <Button className="search-button" color="primary"
                                    onClick={() => this.submitSearch()}>Search</Button>
                            </Col>
                            <Col className="align-self-end col-md-auto">
                                <Button className="search-button" color="secondary"
                                    onClick={() => this.resetFilters() }>Reset</Button>
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={1}>
                                <FormGroup>
                                    <Label for="version">Version</Label>
                                    <Input type="select" name="select" id="versionSelect"
                                        onChange={e => this.versionChanged(e.target.value)}
                                        value={this.state.sdkVersion || ''}>
                                        <option key="all" value="">All</option>
                                        {this.state.filtersData?.availableVersions.map(version => (
                                            <option key={version} value={version}>{version}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                    }
                </Container>
                <Spinner color="primary" className={this.state.isSearching ? "visible" : "invisible"}></Spinner>

                {this.state.data &&
                    <Container>
                        <TransactionList columns={columns} result={this.state.data} searchData={this.requestFromState()} />
                    
                        <Paginator pageCount={this.state.data.pageCount} changePage={(page: number) => this.handlePageChange(page)} currentPage={this.state.data.currentPage}></Paginator>
                    </Container>
                }

            </Container>
        );
    }

    private handlePageChange(page: number) {
        this.props.history.push(this.props.location.pathname + "?page=" + page);
    }

    private isFromDateValid() {
        return this.state.fromDate !== ''
    }

    private isToDateValid() {
        return this.state.toDate !== ''
    }

    private fromDateChanged(fromDate: string) {
        this.setState({
            fromDate: fromDate
        });
        sessionStorage.setItem("fromDate", fromDate);
    }

    private toDateChanged(toDate: string) {
        this.setState({
            toDate: toDate
        });
        sessionStorage.setItem("toDate", toDate);
    }

    private apiKeyChanged(apiKey: string) {
        this.setState({
            apiKey: apiKey
        });
        sessionStorage.setItem("apiKey", apiKey);
    }

    private versionChanged(version: string) {
        this.setState({
            sdkVersion: version
        });
        sessionStorage.setItem("sdkVersion", version);
    }

    private getFiltersData() {
        ReportsService.getFiltersData()
            .then((data) => {
                this.setupStateFrom(data)
            })
    }

    private setupStateFrom(filtersData: ReportFiltersData) {
        const apiKey = sessionStorage.getItem("apiKey")
        const fromDate = sessionStorage.getItem("fromDate")
        const toDate = sessionStorage.getItem("toDate")
        const sdkVersion = sessionStorage.getItem("sdkVersion")

        this.setState({
            apiKey: apiKey || undefined,
            fromDate: fromDate || undefined,
            toDate: toDate || undefined,
            sdkVersion: sdkVersion || undefined,
            filtersData: filtersData
        }, () => {
            this.submitSearch()
        });
    }

    private resetFilters() {
        this.setState({
            fromDate: "",
            toDate: "",
            sdkVersion: "",
            data: undefined
        });
    }

    private requestFromState() {
        var request: ReportsSearchRequest = {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            apiKey: this.state.apiKey,
            sdkVersion: this.state.sdkVersion
        }

        return request;
    }

    private submitSearch() {
        if(this.state.apiKey === undefined) {
            return
        }

        this.setState({
            isSearching: true
        });

        ReportsService.search(this.props.location.search, this.requestFromState())
            .then((page) => {
                this.setState({
                    data: page,
                    isSearching: false
                });
            }).catch((error) => {
                ToastService.showToast("Error applying search filters", error.response.data.detail);
                this.setState({
                    isSearching: false
                });
            });;
    }
}
