import { UserManager, UserManagerSettings } from "oidc-client";
import { Subject } from "rxjs";
import configs from "../../configs";


export class Authenticator {

    private readonly _userManager: UserManager;

    public isLoggedIn$: Subject<boolean>;

    public constructor() {
        const settings: UserManagerSettings = {
            authority: configs.authority,
            client_id: configs.client_id,
            redirect_uri: window.location.href,
            post_logout_redirect_uri: window.location.href,
            scope: configs.scope,

            response_type: configs.response_type,

            monitorSession: false,

            metadata: {
                authorization_endpoint: configs.authorization_endpoint,
                token_endpoint: configs.token_endpoint,
                end_session_endpoint: configs.logout_endpoint
            }
        }

        this._userManager = new UserManager(settings)
        this.isLoggedIn$ = new Subject<boolean>();
    }

    public async login(): Promise<void> {
        const user = await this._userManager.getUser()

        if (!user) {
            const data = {
                redirectUrl: window.location.href.length > 0 ? window.location.href : "#",
            };
    
            try {
                await this._userManager.signinRedirect({state: data});
            } catch (e: any) {
                throw Error(e)
            }
        } else {
            this.isLoggedIn$.next(true);
        }
    }

    public async logout(): Promise<void> {
        await this._userManager.signoutRedirect();

    }

    public async getAccessToken(): Promise<string|undefined> {
        const user = await this._userManager.getUser();

        if (user && user.access_token) {
            return user.access_token;
        }

        throw Error("access token not set");
    }

    public async clearAccessToken(): Promise<void> {
        await this._userManager.removeUser();
        this.isLoggedIn$.next(false);
    }

    public async handleLoginResponse(): Promise<void> {
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams && urlParams.has("state")) {

            // Only try to process a login response if the state exists
            const storedState = await this._userManager.settings.stateStore?.get(urlParams.get("state")!);
            if (storedState) {

                let redirectLocation = '#';
                try {
                    // Handle the login response
                    const user = await this._userManager.signinRedirectCallback();

                    // We will return to the app location before the login redirect
                    redirectLocation = user.state.redirectUrl;

                    // Delete any local storage redirect state older than 5 minutes for incomplete login redirects
                    await this._userManager.clearStaleState();

                    this.isLoggedIn$.next(true);

                } catch (e: any) {
                    // Handle and rethrow OAuth response errors
                    throw Error(e);

                } finally {
                    // Navigate to where the user wanted to access before the login
                    window.location.replace(redirectLocation);
                }
            }
        }
    }
}

const authenticator = new Authenticator();
export default authenticator;