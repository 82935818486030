import * as locale from 'locale-codes'
import { useState } from "react";
import Select from 'react-select';
import { LanguageOption } from "../../../common/models/languageOption";
import { Button, Col, FormFeedback, Container, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { FileInput } from "../../../common/components/file-input/FileInput";
import ToastService from "../../../common/services/toast-service";
import { AddLocalisationsRequest } from "./../../common/models/add-localisations-request";
import "./LocalisationAddModal.css";

interface LocalisationAddProps {
    isOpen: boolean;
    apiKey: string;

    toggle: () => void;
    submit: (language: string, request: AddLocalisationsRequest) => void;
};

export const LocalisationAddModal = (props: LocalisationAddProps) => {

    const [language, setLanguage] = useState<string | undefined>(undefined);
    const [iOSJsonFile, setIOSJsonFile] = useState<File | undefined>(undefined);
    const [androidJsonFile, setAndroidJsonFile] = useState<File | undefined>(undefined);

    const isLanguageValid = (): boolean => {
        return language !== undefined && iOSJsonFile !== undefined && androidJsonFile !== undefined;
    }

    const submit = () => {
        if (language !== undefined && iOSJsonFile !== undefined && androidJsonFile !== undefined) {

            const iOSReader = new FileReader()
            iOSReader.onload = function (iOSJsonFile) {

                const androidReader = new FileReader()
                androidReader.onload = function (androidJsonFile) {

                    let iOSContent = iOSJsonFile.target?.result?.toString()
                    let androidContent = androidJsonFile.target?.result?.toString()

                    if (iOSContent !== undefined && androidContent !== undefined) {

                        try {
                            var iOSLocalisations: { [key: string]: string } = JSON.parse(iOSContent)
                            var androidLocalisations: { [key: string]: string } = JSON.parse(androidContent)

                            let request: AddLocalisationsRequest = {
                                iOSLocalisations: iOSLocalisations,
                                androidLocalisations: androidLocalisations
                            }

                            props.submit(language, request)
                            toggle()

                        } catch (e) {
                            ToastService.showToast("Invalid json files", "")
                        }
                    }
                    
                }

                androidReader.readAsText(androidJsonFile)
            };

            iOSReader.readAsText(iOSJsonFile)
        }
        
    }

    const toggle = () => {
        props.toggle();
        resetState();
    }

    const resetState = () => {
        setLanguage(undefined);
        setIOSJsonFile(undefined);
        setAndroidJsonFile(undefined);
    }

    const languageOptions = (): LanguageOption[] => {
        return locale.all.map((locale, i) => {
            if (locale.location !== null) {
                return { value: locale.tag, label: locale.name + " - " + locale.location + " (" + locale.tag + ")" }
            } else {
                return { value: locale.tag, label: locale.name + " (" + locale.tag + ")" }
            }
        })
    }

    return (
        <Container>
            {props.isOpen &&
                <Modal isOpen={props.isOpen} toggle={() => props.toggle()} size="md" unmountOnClose={true}>
                    <ModalHeader toggle={() => props.toggle()}>Add Language</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Container>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="language">Language</Label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                isClearable={true}
                                                isSearchable={true}
                                                name="language"
                                                onChange={e => setLanguage(e?.value)}
                                                options={languageOptions()}
                                            />

                                            <FormFeedback>This field is required</FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <FileInput label="iOS JSON File:" accept=".json" onFileSelected={setIOSJsonFile} />
                                            <FileInput label="android JSON File:" accept=".json" onFileSelected={setAndroidJsonFile} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            disabled={!isLanguageValid()}
                            onClick={() => submit()}>Confirm</Button>
                        <Button color="secondary" onClick={() => toggle()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            }
        </Container>
    );

}