import { PagedResult } from "../../../common/models/paged-result";
import { TransactionDetailSummary } from "../../common/models/transaction-detail-summary-dto";
import { Table } from "reactstrap";
import { ColumnDef } from '../ReportDetail';
import { DeviceData } from '../../common/models/device-data';

var androidDevices = require('android-device-list');

const TransactionDetailList = (props: { columns: ColumnDef[], result: PagedResult<TransactionDetailSummary>}) => {

    return <Table bordered responsive striped size="lg">
        <thead>
            <tr>
                {props.columns.map(column => (<th key={column.accessor}>{column.header}</th>))}
            </tr>
        </thead>
        {props.result.resources.length === 0 &&
            <tbody>
                <tr>
                    <td colSpan={(props.columns.length + 1)}>No Data Available</td>
                </tr>
            </tbody>
        }
        {props.result.resources.length > 0 &&
            <tbody>
                {props.result.resources.map((row, i) => (
                    <tr key={i}>
                        {props.columns.map(column => (
                            <td key={i + '-' + column.accessor}>
                                {getValue((row as { [key: string]: any })[column.accessor], column.accessor)}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        }
    </Table>
}

const getValue = (origValue: any, key: string) => {
    if (origValue !== null) {
        if (key === 'status') {
            return origValue === true ? "Success" : "Failure";
        }

        if (key === 'deviceModel') {
            var dataForModel: [DeviceData] = androidDevices.getDevicesByModel(origValue)
            if(dataForModel.length > 0) {
                return String(dataForModel[0].name + " (" + origValue + ")");
            }
        }

        return String(origValue)
    }
}

export default TransactionDetailList;

