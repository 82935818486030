import { createContext, FC, useContext, useEffect, useState } from "react";
import authenticator from "../common/authentication/authenticator";
import ToastService from "../common/services/toast-service";

interface IAuthenticationContext {
  isLoggedIn: Boolean;
}

const authContext = createContext<IAuthenticationContext>({} as IAuthenticationContext);

const Provider: FC = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const login = async () => {
    try {
      await authenticator.handleLoginResponse();
      await authenticator.login();
    } catch (error) {
      ToastService.showToast("Authentication Error", "Error logging in", false)
    }
  };

  useEffect(() => {
    authenticator.isLoggedIn$.subscribe(isLoggedIn => setIsLoggedIn(isLoggedIn));
    login();
  }, []);

  return (
    <authContext.Provider value={{ isLoggedIn }}>
      {children}
    </authContext.Provider>
  );
};

const useAuthenticationContext = () => useContext(authContext);

const AuthenticationContext = {
  Provider,
  useAuthenticationContext,
}

export default AuthenticationContext;
