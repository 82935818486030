import { PagedResult } from "../../../common/models/paged-result";
import { TransactionSummary } from "../../common/models/transaction-summary-dto";
import { Table } from "reactstrap";
import TransactionCell from "./TransactionCell";
import { ColumnDef } from '../Reports';
import { ReportsSearchRequest } from "../../common/models/reports-search-request";

const TransactionList = (props: { columns: ColumnDef[], result: PagedResult<TransactionSummary>, searchData: ReportsSearchRequest }) => {

    return <Table bordered responsive striped size="lg">
            <thead>
                <tr>
                    {props.columns.map(column => (<th key={column.accessor}>{column.header}</th>))}
                </tr>
            </thead>
            {props.result.resources.length === 0 &&
                <tbody>
                    <tr>
                    <td colSpan={(props.columns.length + 1)}>No Data Available</td>
                    </tr>
                </tbody>
            }
            {props.result.resources.length > 0 &&
                <tbody>
                {props.result.resources.map((row, i) => (
                        <tr key={i}>
                        {props.columns.map(column => (
                            <td key={i + '-' + column.accessor}>
                                <TransactionCell searchData={props.searchData} featureType={column.accessor} data={row} />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            }
        </Table>
}

export default TransactionList;

